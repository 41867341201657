<script lang="ts">
	export let height = '48px' // Default height
	export let width = '48px' // Default width
	let className = ''
	export { className as class }
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	><path
		d="M10 32h28v6H10zm0 -10h28v6H10zm0 -10h28v6H10z"
		fill="currentColor"
		stroke-width="1"
	/></svg
>
